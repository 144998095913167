<template>
  <div id="users-activity">
    <b-card title="Users Activity">
      <b-row>
        <b-col md="2"
          ><b-form-group label="From Date">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              locale="en"
              v-model="filterStartDate"
            /> </b-form-group
        ></b-col>
        <b-col md="2"
          ><b-form-group label="To Date">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              locale="en"
              v-model="filterEndDate"
            /> </b-form-group
        ></b-col>
        <b-col md="5">
          <b-form-group label="User">
            <v-select v-model="filterUser" :options="filterUserOptions" clearable />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Activity Type">
            <v-select v-model="filterType" :options="filterTypeOptions" clearable />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body header="Activities">
      <b-table
        ref="activitiesTable"
        :fields="activitiesTableColumns"
        :items="loadActivities"
        responsive
        show-empty
        :empty-html="isLoading ? 'Loading...' : 'No data found'"
      >
        <template #cell(user)="data">
          <b-link target="_blank" :href="`/profile/${data.item.username}`">{{
            data.item.user
          }}</b-link>
          <div class="text-muted">{{ data.item.userType }}</div>
        </template>
        <template #cell(resource)="data">
          <b-link
            target="_blank"
            :href="`/profile/details/${data.item.resProfile._id}`"
            v-if="data.item.typeValue == 'added_profile' && data.item.resProfile"
            >{{ data.item.resProfile.name }}</b-link
          >
          <b-link
            target="_blank"
            :href="`/profile/details/${data.item.resAuction.profileId}`"
            v-if="data.item.typeValue == 'made_auction'"
            >{{ data.item.resAuction.belongFor }}</b-link
          >
          <b-link
            target="_blank"
            :href="`/pages/blog/${data.item.resPost._id}`"
            v-if="data.item.typeValue == 'created_post'"
            >{{ data.item.resPost.title }}</b-link
          >
        </template>
      </b-table>
    </b-card>
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
        >
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalActivities"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTable,
  BFormInput,
  BFormGroup,
  BButton,
  BFormDatepicker,
  BLink,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useActivity from "./useActivity";

/* Export the components */
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BFormInput,
    BFormGroup,
    BButton,
    BFormDatepicker,
    BLink,
    BBadge,
    vSelect,
    BPagination,
  },
  setup() {
    const {
      filterStartDate,
      filterEndDate,
      filterUser,
      filterType,
      filterTypeOptions,
      filterUserOptions,
      activitiesTableColumns,
      loadActivities,
      activitiesTable,
      dataMeta,
      totalActivities,
      perPage,
      currentPage,
      isLoading,
    } = useActivity();

    return {
      filterStartDate,
      filterEndDate,
      filterUser,
      filterType,
      filterTypeOptions,
      filterUserOptions,
      activitiesTableColumns,
      loadActivities,
      activitiesTable,
      dataMeta,
      totalActivities,
      perPage,
      currentPage,
      isLoading,
    };
  },
};
</script>
