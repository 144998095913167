import { ref, watch, computed } from "@vue/composition-api";
import moment from "moment";
import axios from "axios";

export default function useActivity() {
  /* 
		Filter references
	*/
  const filterStartDate = ref(moment().startOf("month").format("YYYY-MM-DD"));
  const filterEndDate = ref(moment().format("YYYY-MM-DD"));
  const filterUser = ref(null);
  const filterType = ref(null);
  const perPage = ref(10);
  const currentPage = ref(1);
  const totalActivities = ref(0);
  const activitiesTable = ref(null);
  const isLoading = ref(false);
  const activitiesTableColumns = [
    {
      key: "date",
      label: "Date/Time",
    },
    {
      key: "user",
      label: "User",
    },
    {
      key: "type",
      label: "Type",
    },
    {
      key: "resource",
      label: "Resource",
    },
  ];

  /* 
		Filter Options 
	*/
  const filterTypeOptions = [
    { label: "Created Profile", value: "added_profile" },
    { label: "Made Auction", value: "made_auction" },
    { label: "Created Blog Post", value: "created_post" },
  ];
  const filterUserOptions = ref([]);

  /* 
		Methods 
	*/
  const loadUsers = async () => {
    try {
      const { data } = await axios.get("users");
      if (data.data.length == 0) {
        return [];
      }

      filterUserOptions.value = data.data.map((x) => {
        return { label: `${x.username} - ${x.userType}`, value: x._id };
      });
    } catch (e) {
      console.log("Unable to load users");
    }
  };
  loadUsers();

  const loadActivities = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("startDate", filterStartDate.value ? filterStartDate.value : "");
      formData.append("endDate", filterEndDate.value ? filterEndDate.value : "");
      formData.append("userId", filterUser.value ? filterUser.value.value : "");
      formData.append("activityType", filterType.value ? filterType.value.value : "");
      formData.append("perPage", perPage.value);
      formData.append("page", currentPage.value);

      const { data } = await axios.post("activities/search/", formData);
      if (data.data.length == 0) {
        return [];
      }
      totalActivities.value = data.count;
      return data.data.map((x) => {
        return {
          date: moment(x.createdAt).format("DD/MM/YYYY hh:mm A"),
          user: x.userId?.username,
          userType: x.userId?.userType,
          username: x.userId?.username,
          resPost: x.resPost,
          resAuction: x.resAuction,
          resProfile: x.resProfile,
          typeValue: x.type,
          type: filterTypeOptions.find((z) => {
            return z.value == x.type;
          }).label,
          resource: x.resource,
        };
      });
    } catch (e) {
      console.log("Unable to load activities", e.message);
      return [];
    } finally {
      isLoading.value = false;
    }
  };

  watch([filterStartDate, filterEndDate, filterUser, filterType, currentPage], () => {
    activitiesTable.value.refresh();
  });

  const dataMeta = computed(() => {
    const localItemsCount = activitiesTable.value ? activitiesTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalActivities.value,
    };
  });

  return {
    /* fields */
    filterStartDate,
    filterEndDate,
    filterUser,
    filterType,
    activitiesTable,
    activitiesTableColumns,
    dataMeta,
    totalActivities,
    perPage,
    currentPage,

    /* options */
    filterTypeOptions,
    filterUserOptions,

    /* METHODS */
    loadActivities,
    isLoading,
  };
}
